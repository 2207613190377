import { color as tempoColor } from '@nowsta/ux-common-components';

export const routes = {
  home: '/',
  login: '/login',
  rates: '/rates',
  invoices: '/invoices',
  invoicesHistory: '/invoices-history',
  positions: '/positions',
  payments: '/payables',
  notFound: '/404',
  unauthorized: '/401',
  agencies: '/agencies',
  orderAllocation: '/order-allocation',
};

export const breakpoints = {
  small: '640px',
  medium: '930px',
};

export const color = {
  black: '#000000',
  blackPearl: '#20282B',
  blueBayoux: '#607078',
  pattensBlue: '#dde2e6',
  skeletonBg: '#d6d6d6',
  skeletonFg: '#ecebeb',
  white: '#ffffff',
  ...tempoColor,
};

export const GLOBAL_COOKIE_NAME = 'dradatacookie';

export enum QueryKeys {
  agenciesWidthRatesWithCompanyAndPosition = 'agenciesWidthRatesWithCompanyAndPosition',
  agenciesWidthRatesWithCompany = 'agenciesWidthRatesWithCompany',
  invoices = 'invoices',
  clientInvoices = 'clientInvoices',
  invoicesHistory = 'invoicesHistory',
  invoiceErrors = 'invoiceErrors',
  paymentErrors = 'paymentErrors',
  companies = 'companies',
  agencies = 'agencies',
  departments = 'wfmDeparments',
  payments = 'payments',
  orderAllocation = 'orderAllocation',
  findOrderAllocation = 'findOrderAllocation',
  punchcardsList = 'punchcardsList',
  eventsData = 'eventsData',
  positionsData = 'positionsData',
  agencyReceivables = 'agencyReceivables',
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  STALE = 'STALE',
}

export enum InvoicingTerm {
  net15 = 'net15',
  net30 = 'net30',
  net45 = 'net45',
}

export const DEFAULT_TIMEZONE = 'America/New_York';
export const MONTH_DAY_YEAR_FORMAT = 'MMM d, yyyy';
