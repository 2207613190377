export enum PunchcardStatus {
  INVOICED = 'Invoiced',
  NEEDS_TO_BE_INVOICED = 'Needs to be Invoiced',
  CORRECTED_NEEDS_TO_BE_INVOICED = 'Corrected - Needs to be Invoiced',
  STALE = 'Stale',
  PAID_TO_AGENCY = 'Paid to Agency',
  PAID_BY_CLIENT = 'Paid by Client',
}

export type StatusKey = keyof typeof PunchcardStatus;

export interface SearchPunchcardsForm {
  companyId?: string;
  startDate?: string;
  page: number;
  limit: number;
  endDate?: string;
  event?: string;
  eventDepartment?: string;
  position?: string;
  positionDepartment?: string;
  status: Record<keyof typeof PunchcardStatus, boolean>;
}

export interface PunchcardItem {
  id: number;
  externalPunchcardId: string;
  clockInTime: string;
  clockOutTime: string;
  workerFirstName: string;
  workerLastName: string;
  positionName: string;
  jobName: string;
  regularRateCents: number;
  regularRateHours: number;
  regularTotalCents: number;
  overtimeRateCents: number;
  overtimeRateHours: number;
  overtimeTotalCents: number;
  doubleOvertimeRateCents: number;
  doubleOvertimeRateHours: number;
  doubleOvertimeTotalCents: number;
  tipPayTotalCents: number;
  reimbursementPayTotalCents: number;
  travelPayTotalCents: number;
  grandTotalCents: number;
  clientBillRateCents: number;
  agencyPayRateCents: number;
  status: string;
  companyId: number;
  invoiceId: null;
  externalSource: string;
  productType: string;
  companyTimezone: string;
  invoiceItemTimezone: string;
  eventDepartmentName: string;
  positionDepartmentName: string;
  bonusCents: number;
  driveTimeRateCents: number;
  driveTimeMinutes: number;
  minimumHours: number;
}
