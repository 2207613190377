import { Edit } from '@nowsta/tempo-ds';
import { ReactComponent as CSVIcon } from 'assets/csv.svg';

import { InvoiceItem } from 'features/invoices/types';
import { formatInTimeZone } from 'date-fns-tz';

import { dowloadInvoiceCSV } from 'services';
import * as Presenter from './Presenter';
import {
  DEFAULT_TIMEZONE,
  MONTH_DAY_YEAR_FORMAT,
} from '../../../common/constants';

export const HEADER_COLUMNS = [
  {
    header: 'Invoice #',
    id: 'invoiceNumber',
    accessorKey: 'invoiceNumber',
  },
  {
    header: 'Company name',
    id: 'companyName',
    accessorKey: 'companyName',
  },
  {
    header: 'Date range',
    id: 'dateRange',
    accessorKey: 'dateRange',
  },
  {
    header: 'Term',
    id: 'term',
    accessorKey: 'term',
  },
  {
    header: 'Status',
    id: 'status',
    accessorKey: 'status',
  },
  {
    header: 'Invoice requester',
    id: 'invoiceRequester',
    accessorKey: 'invoiceRequester',
  },
  {
    header: 'Created at',
    id: 'createdAt',
    accessorKey: 'createdAt',
  },
  {
    header: 'Invoice file',
    id: 'invoiceFile',
    accessorKey: 'invoiceFile',
  },
  {
    header: '',
    id: 'edit',
    accessorKey: 'edit',
  },
];

interface Options {
  onEdit: (invoiceId: number) => void;
}

export const getInvoiceData = (invoice: InvoiceItem, { onEdit }: Options) => ({
  invoiceNumber: invoice.invoiceNumber,
  companyName: invoice.company.name,
  dateRange: `${formatInTimeZone(
    new Date(invoice.startDate),
    invoice.company.timeZone || DEFAULT_TIMEZONE,
    MONTH_DAY_YEAR_FORMAT,
  )} - ${formatInTimeZone(
    new Date(invoice.endDate),
    invoice.company.timeZone || DEFAULT_TIMEZONE,
    MONTH_DAY_YEAR_FORMAT,
  )}`,
  term: invoice.term,
  status: invoice.status,
  invoiceRequester: invoice.createdByName,
  createdAt: `${formatInTimeZone(
    new Date(invoice.createdAt),
    DEFAULT_TIMEZONE,
    'MMM d, yyyy h:mm a z',
  )}`,
  invoiceFile: invoice.csvFileURL ? (
    <Presenter.CSVButton
      onClick={() => {
        dowloadInvoiceCSV(invoice.id);
      }}
      type="button"
    >
      <CSVIcon />
    </Presenter.CSVButton>
  ) : null,
  edit: (
    <Presenter.CSVButton onClick={() => onEdit(invoice.id)} type="button">
      <Edit />
    </Presenter.CSVButton>
  ),
});
