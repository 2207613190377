import { formatInTimeZone } from 'date-fns-tz';

import { PunchcardItem } from 'features/punchcards/types';
import { DEFAULT_TIMEZONE } from 'features/common/constants';

interface GetTableData {
  punchcard: PunchcardItem;
}

const DEFAULT_STATUS = 'Never invoiced';

const getFormatMoney = (value?: number) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return USDollar.format(value || 0);
};

export const getTableData = ({ punchcard }: GetTableData) => {
  const regularRateInDollars = punchcard?.regularRateCents
    ? punchcard.regularRateCents / 100
    : undefined;
  const regularTotalCentsInDollars = punchcard?.regularTotalCents
    ? punchcard.regularTotalCents / 100
    : undefined;
  const doubleOvertimeRateCentsInDollars = punchcard?.doubleOvertimeRateCents
    ? punchcard.doubleOvertimeRateCents / 100
    : undefined;
  console.log('HUH', punchcard);
  const overtimeTotalCentsInDollars = punchcard?.overtimeTotalCents
    ? punchcard.overtimeTotalCents / 100
    : undefined;
  const tipPayTotalCentsInDollars = punchcard?.tipPayTotalCents
    ? punchcard.tipPayTotalCents / 100
    : undefined;
  const reimbursementPayTotalCentsInDollars =
    punchcard?.reimbursementPayTotalCents
      ? punchcard.reimbursementPayTotalCents / 100
      : undefined;
  const travelPayTotalCentsInDollars = punchcard?.travelPayTotalCents
    ? punchcard.travelPayTotalCents / 100
    : undefined;
  const grandTotalCentsInDollars = punchcard?.grandTotalCents
    ? punchcard.grandTotalCents / 100
    : undefined;
  const bonusCentsInDollars = punchcard?.bonusCents
    ? punchcard.bonusCents / 100
    : undefined;
  const driveTimeRateCentsInDollars = punchcard?.driveTimeRateCents
    ? punchcard.driveTimeRateCents / 100
    : undefined;
  const overtimeRateCentsInDollars = punchcard?.overtimeRateCents
    ? punchcard.overtimeRateCents / 100
    : undefined;
  const doubleOvertimeTotalCentsInDollars = punchcard?.doubleOvertimeTotalCents
    ? punchcard.doubleOvertimeTotalCents / 100
    : undefined;

  return {
    external_source: String(punchcard?.externalSource).toUpperCase(),
    external_punchcard_id: punchcard?.externalPunchcardId,
    name: `${punchcard?.workerFirstName} ${punchcard?.workerLastName}`,
    position: punchcard?.positionName,
    event: punchcard?.jobName,
    date: formatInTimeZone(
      new Date(punchcard.clockInTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'MMM d, yyyy',
    ),
    clock_in: formatInTimeZone(
      new Date(punchcard.clockInTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'h:mm a',
    ),
    clock_out: formatInTimeZone(
      new Date(punchcard.clockOutTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'h:mm a',
    ),
    regular_hours: punchcard?.regularRateHours,
    regular_rate: getFormatMoney(regularRateInDollars),
    regular_total: getFormatMoney(regularTotalCentsInDollars),
    overtime_hours: punchcard?.overtimeRateHours,
    double_overtime_rate: getFormatMoney(doubleOvertimeRateCentsInDollars),
    overtime_total: getFormatMoney(overtimeTotalCentsInDollars),

    tip_pay_total: getFormatMoney(tipPayTotalCentsInDollars),
    reimbursement_pay_total: getFormatMoney(
      reimbursementPayTotalCentsInDollars,
    ),
    travel_pay_total: getFormatMoney(travelPayTotalCentsInDollars),
    grand_total: getFormatMoney(grandTotalCentsInDollars),
    event_department_name: punchcard?.eventDepartmentName,
    position_department_name: punchcard?.positionDepartmentName,
    status: punchcard?.status ?? DEFAULT_STATUS,
    product: punchcard?.productType,
    bonus_cents: getFormatMoney(bonusCentsInDollars),
    drive_time_rate_cents: getFormatMoney(driveTimeRateCentsInDollars),
    drive_time_minutes: punchcard?.driveTimeMinutes,
    minimum_hours: punchcard?.minimumHours,
    overtime_rate: getFormatMoney(overtimeRateCentsInDollars),
    double_overtime_hours: punchcard.doubleOvertimeRateHours,
    double_overtime_total: getFormatMoney(doubleOvertimeTotalCentsInDollars),
  };
};
